<template>
  
</template>

<script>
export default {
  name: "Blank",

  data: () => ({


  })
};
</script>
